import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [3,5,6];

export const dictionary = {
		"/(home)": [13,[3]],
		"/(home)/confirm-newsletter": [17,[3]],
		"/(home)/confirm-ownership": [18,[3]],
		"/(home)/confirm-ownership/create": [19,[3]],
		"/(home)/create-project": [20,[3]],
		"/demo": [23],
		"/(auth)/forgot-password": [7,[2]],
		"/(auth)/forgot-password/success": [8,[2]],
		"/(home)/(legal)/imprint": [14,[3,4]],
		"/(auth)/login": [9,[2]],
		"/(auth)/logout": [~10,[2]],
		"/my": [24,[5]],
		"/my/account": [32,[5]],
		"/my/account/api-keys": [33,[5]],
		"/my/account/payment": [34,[5]],
		"/my/account/payment/authenticate": [~35,[5]],
		"/my/account/payment/stripe/setup": [~36,[5]],
		"/my/account/plan": [~37,[5]],
		"/my/(project)/crawling": [25,[5,6]],
		"/my/(project)/integrations": [26,[5,6]],
		"/my/(project)/integrations/google-tag-manager": [27,[5,6]],
		"/my/(project)/integrations/jssnippet": [28,[5,6]],
		"/my/(project)/overview": [29,[5,6]],
		"/my/(project)/preview": [30,[5,6]],
		"/my/(project)/ui-designer": [31,[5,6]],
		"/(auth)/preauth": [~11,[2]],
		"/(home)/(legal)/privacy": [~15,[3,4]],
		"/(auth)/reset-password/[slug]": [12,[2]],
		"/(home)/start": [21,[3]],
		"/(home)/start/create": [22,[3]],
		"/(home)/(legal)/terms": [16,[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';