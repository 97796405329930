import { appLocale } from '$lib/stores/localeStore';
import { addApiMiddleware, type FetchParams, type RequestContext } from '@mandelbaum/api-client';
import { get } from 'svelte/store';

addApiMiddleware({
  async pre(context: RequestContext): Promise<FetchParams | void> {
    context.init.headers = {
      'App-Locale': get(appLocale),
      ...context.init.headers,
    };

    return context;
  },
});
