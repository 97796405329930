import { browser } from '$app/environment';
import { AUTH_REPLACE_MARKER, JWT_COOKIE } from '$lib/types/auth';
import { ClientCookies } from '$lib/utils/cookie.client';
import { OpenAPI } from '@mandelbaum/api-client';

OpenAPI.accessToken = () => {
  if (browser) {
    return ClientCookies.get(JWT_COOKIE) ?? '';
  }

  // return non-empty placeholder string on server, will be filled by a fetch hook
  return AUTH_REPLACE_MARKER;
};
