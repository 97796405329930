import { env } from '$env/dynamic/public';
import { setupJwtRefresh } from '$lib/utils/jwt-refresh';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: env.PUBLIC_SENTRY_DSN,
  environment: env.PUBLIC_APP_ENV,
  release: env.PUBLIC_APP_VERSION || undefined,
});

setupJwtRefresh();
export const handleError = Sentry.handleErrorWithSentry();
